import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApiStatusChecker.css'; // Assuming you want to add custom styling in a CSS file

const ApiStatusChecker: React.FC = () => {
  const [isApiUp, setIsApiUp] = useState<boolean | null>(null);
  const [previousApiStatus, setPreviousApiStatus] = useState<boolean | null>(null);

  useEffect(() => {
    const checkApiStatus = async () => {
      if (!navigator.onLine) {
        setIsApiUp(false);
        return;
      }

      try {
        const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
        const response = await axios.get(`${apiUrl}/api/conversation/api-status`);
        const currentStatus = response.data.isOpenAiApiUp;

        setIsApiUp(currentStatus);

        // Update the previous API status
        setPreviousApiStatus(currentStatus);
      } catch (error) {
        setIsApiUp(false);
      }
    };

    const interval = setInterval(checkApiStatus, 30000); // Check API status every 30 seconds
    checkApiStatus(); // Check the status immediately on component mount

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`api-status-popup ${isApiUp ? 'up' : 'down'}`}>
      {isApiUp ? 'The API is up and running!' : 'The API is currently unavailable.'}
    </div>
  );
};

export default ApiStatusChecker;
