import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Users, Briefcase, Trophy } from 'lucide-react';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

interface JobInfo {
  title: string;
  department: string;
  seniorityLevel: string;
}

interface Configuration {
  id: number;
  jobInfo: JobInfo;
  selectedQuestions: string[];
}

const HRInterviewDashboard: React.FC = () => {
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await axios.get<Configuration[]>(`${apiUrl}/api/HRConfigs/user-configs`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setConfigurations(response.data);
      } catch (err) {
        console.error('Error fetching configurations:', err);
        setError('Failed to fetch configurations');
      }
    };

    fetchConfigurations();
  }, []);

  const handleCreateNew = () => {
    // Implement navigation to create new configuration page
    console.log('Navigate to create new configuration page');
  };

  const handleEdit = (configId: number) => {
    // Implement edit functionality
    console.log('Edit configuration:', configId);
  };

  const handleDelete = (configId: number) => {
    // Implement delete functionality
    console.log('Delete configuration:', configId);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-blue-600">AI-Powered HR Interview Dashboard</h1>
      
      <Button onClick={handleCreateNew} className="mb-6 bg-green-500 hover:bg-green-600">
        + Create New Configuration
      </Button>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {configurations.map((config) => (
          <Card key={config.id} className="shadow-lg">
            <CardContent className="p-6">
              <h2 className="text-xl font-semibold mb-2 text-blue-500">{config.jobInfo.title}</h2>
              
              <div className="flex items-center mb-2">
                <Users className="mr-2 h-4 w-4" />
                <span>{config.jobInfo.department}</span>
              </div>
              
              <div className="flex items-center mb-2">
                <Briefcase className="mr-2 h-4 w-4" />
                <span>Seniority: {config.jobInfo.seniorityLevel}</span>
              </div>
              
              <div className="flex items-center mb-4">
                <Trophy className="mr-2 h-4 w-4" />
                <span>{config.selectedQuestions.length} questions</span>
              </div>

              <div className="flex justify-between mt-4">
                <Button onClick={() => handleEdit(config.id)} variant="outline" className="bg-yellow-500 text-white hover:bg-yellow-600">
                  Edit
                </Button>
                <Button onClick={() => handleDelete(config.id)} variant="outline" className="bg-red-500 text-white hover:bg-red-600">
                  Delete
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HRInterviewDashboard;