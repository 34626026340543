import { useEffect, useRef } from 'react';

interface UseKeyHandlersProps {
  isRecording: boolean;
  isInCall: boolean;
  canTalk: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  setIsSpeaking: (isSpeaking: boolean) => void;
  setSpeakingDuration: React.Dispatch<React.SetStateAction<number>>;
  isTalking: boolean; // Add isTalking as a prop to track AI speaking state
  triggerWarning: () => void; // Add triggerWarning as a prop to show the interruption warning
}

const useKeyHandlers = ({
  isRecording,
  isInCall,
  canTalk,
  startRecording,
  stopRecording,
  setIsSpeaking,
  setSpeakingDuration,
  isTalking, // New prop
  triggerWarning, // New prop
}: UseKeyHandlersProps) => {
  const speakingTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space' && !isRecording && isInCall && canTalk) {
        event.preventDefault();
        startRecording();
        setIsSpeaking(true);
        setSpeakingDuration(0);

        // Check for interruption
        if (isTalking) {
          triggerWarning(); // Trigger the warning if the AI is speaking
        }

        speakingTimer.current = setInterval(() => {
          setSpeakingDuration((prevDuration) => prevDuration + 1);
        }, 100);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space' && isRecording) {
        event.preventDefault();
        stopRecording();
        setIsSpeaking(false);
        if (speakingTimer.current) {
          clearInterval(speakingTimer.current);
          speakingTimer.current = null;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isRecording, isInCall, canTalk, startRecording, stopRecording, setIsSpeaking, setSpeakingDuration, isTalking, triggerWarning]); // Added dependencies
};

export default useKeyHandlers;
