import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft } from 'lucide-react';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Badge } from '../ui/badge';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

interface ApplicantData {
  score?: number;
  applicant_name?: string;
  department?: string;
  cv_truthfulness?: string;
  language_proficiency?: string[];
  technical_skills?: string[];
  communication_score?: number;
  problem_solving_score?: number;
  cultural_fit_score?: number;
  overall_feedback?: string;
  interview_highlights?: string;
  recommendation?: string;
  call_log?: Array<{ timestamp: string; entry: string }>;
  comparison?: {
    overall_percentile?: number;
    technical_skills_percentile?: number;
    problem_solving_percentile?: number;
    communication_percentile?: number;
    cultural_fit_percentile?: number;
  };
}

const ApplicantDetails: React.FC = () => {
  const [applicantData, setApplicantData] = useState<ApplicantData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplicantData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }
        const response = await axios.get<ApplicantData>(`${apiUrl}/api/testHR/job-interview-report`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { id }
        });
        setApplicantData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching applicant data:', err);
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        setLoading(false);
      }
    };
    fetchApplicantData();
  }, [id]);

  if (loading) {
    return <div className="text-center mt-5">Loading applicant details...</div>;
  }

  if (error) {
    return <div className="text-center mt-5 text-red-600">Error: {error}</div>;
  }

  if (!applicantData) {
    return <div className="text-center mt-5">No applicant data available.</div>;
  }

  const { 
    score,
    applicant_name,
    department,
    cv_truthfulness,
    language_proficiency,
    technical_skills,
    communication_score,
    problem_solving_score,
    cultural_fit_score,
    overall_feedback,
    interview_highlights,
    recommendation,
    call_log,
    comparison
  } = applicantData;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-blue-600">Applicant Details</h1>
      
      <Card className="mb-4">
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div><span className="font-bold">Name:</span> {applicant_name || 'N/A'}</div>
            <div><span className="font-bold">Department:</span> {department || 'N/A'}</div>
            <div><span className="font-bold">Score:</span> <span className="text-green-600 font-bold">{score !== undefined ? score : 'N/A'}</span></div>
            <div><span className="font-bold">CV Truthfulness:</span> <span className="text-green-600">{cv_truthfulness || 'N/A'}</span></div>
            <div><span className="font-bold">Language Proficiency:</span> {language_proficiency ? language_proficiency.join(", ") : 'N/A'}</div>
            <div>
              <span className="font-bold">Technical Skills:</span>
              <div className="flex flex-wrap gap-2 mt-1">
                {technical_skills && technical_skills.map((skill, index) => (
                  <Badge key={index} variant="default" className="bg-pink-500 text-white">{skill}</Badge>
                ))}
              </div>
            </div>
            <div><span className="font-bold">Communication:</span> {communication_score !== undefined ? `${communication_score}/5` : 'N/A'}</div>
            <div><span className="font-bold">Problem Solving:</span> {problem_solving_score !== undefined ? `${problem_solving_score}/5` : 'N/A'}</div>
            <div><span className="font-bold">Cultural Fit:</span> {cultural_fit_score !== undefined ? `${cultural_fit_score}/5` : 'N/A'}</div>
          </div>
        </CardContent>
      </Card>

      {interview_highlights && (
        <Card className="mb-4">
          <CardHeader>
            <h2 className="text-xl font-bold text-blue-600">Interview Highlights</h2>
          </CardHeader>
          <CardContent>
            <p className="whitespace-pre-wrap">{interview_highlights}</p>
          </CardContent>
        </Card>
      )}

      {recommendation && (
        <Card className="mb-4">
          <CardHeader>
            <h2 className="text-xl font-bold text-blue-600">Recommendation</h2>
          </CardHeader>
          <CardContent>
            <p className="font-bold">{recommendation.split('\n')[0]}</p>
            <p className="whitespace-pre-wrap">{recommendation.split('\n').slice(1).join('\n')}</p>
          </CardContent>
        </Card>
      )}

      {call_log && call_log.length > 0 && (
        <Card className="mb-4">
          <CardHeader>
            <h2 className="text-xl font-bold text-blue-600">Interview Call Log</h2>
          </CardHeader>
          <CardContent>
            {call_log.map((entry, index) => (
              <div key={index} className="mb-2">
                <span className="font-bold">{entry.timestamp}</span> - {entry.entry}
              </div>
            ))}
          </CardContent>
        </Card>
      )}

      {comparison && (
        <Card className="mb-4">
          <CardHeader>
            <h2 className="text-xl font-bold text-blue-600">Comparison with Similar Candidates</h2>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-blue-600 text-white">
                    <th className="text-left p-2">Metric</th>
                    <th className="text-left p-2">Percentile</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(comparison).map(([key, value], index) => (
                    <tr key={key} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                      <td className="p-2">{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</td>
                      <td className="p-2">{value !== undefined ? `${value}th` : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      )}

      {overall_feedback && (
        <Card className="mb-4">
          <CardHeader>
            <h2 className="text-xl font-bold text-blue-600">Overall Feedback</h2>
          </CardHeader>
          <CardContent>
            <p>{overall_feedback}</p>
          </CardContent>
        </Card>
      )}

      <button className="flex items-center text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded" onClick={() => navigate(-1)}>
        <ArrowLeft className="mr-2" />
        Back to Dashboard
      </button>
    </div>
  );
};

export default ApplicantDetails;