import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import 'react-tooltip/dist/react-tooltip.css';

console.log('Environment Variable (index.tsx):', process.env.REACT_APP_API_URL);

const container = document.getElementById('app'); // Ensure this matches your index.html ID
if (!container) {
  throw new Error('Failed to find the root element');
}

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
