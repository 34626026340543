import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const COLORS = ['#00C49F', '#FF8042', '#FFBB28', '#0088FE'];

const tooltips: { [key: string]: string } = {
  'open ended': 'Open-ended questions encourage detailed responses and help gather in-depth information.',
  'closed ended': 'Closed-ended questions usually require a yes/no or specific answer, helping to get clear, concise information.',
  probing: 'Probing questions dig deeper into a subject to uncover more detailed information.',
  leading: 'Leading questions suggest a particular answer, helping to guide the conversation in a specific direction.',
};

interface QuestionTypeData {
  name: string;
  count: number;
  percentage: number;
  color: string;
}

interface QuestionTypeDistributionChartProps {
  data: QuestionTypeData[];
}

const QuestionTypeDistributionChart: React.FC<QuestionTypeDistributionChartProps> = ({ data }) => (
  <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer>
      <PieChart>
        <Pie
          dataKey="count"
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#82ca9d"
          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
    <div style={{ textAlign: 'center', position: 'absolute', bottom: 0, width: '100%' }}>
      {data.map((item, index) => (
        <div
          key={index}
          data-tooltip-id="legend-tooltip"
          data-tooltip-content={tooltips[item.name]}
          style={{ display: 'inline-block', margin: '0 10px' }}
        >
          <span style={{ color: COLORS[index % COLORS.length], cursor: 'pointer' }}>
            <svg width="12" height="12" style={{ marginRight: '5px', verticalAlign: 'middle' }}>
              <rect width="12" height="12" style={{ fill: COLORS[index % COLORS.length] }} />
            </svg>
            {item.name} ({item.percentage}%)
          </span>
        </div>
      ))}
      <ReactTooltip id="legend-tooltip" />
    </div>
  </div>
);

export default QuestionTypeDistributionChart;
