import React from 'react';
import { Card } from 'react-bootstrap';

interface QualityFeedback {
  SCORE: number;
  feedback: string;
}

interface CallOpeningClosingQualityProps {
  openingQuality: QualityFeedback;
  closingQuality: QualityFeedback;
}

const scoreToQuality = (score: number) => {
  if (score >= 90) return 'Excellent';
  if (score >= 80) return 'Very Good';
  if (score >= 70) return 'Average';
  if (score >= 60) return 'Below Average';
  return 'Poor';
};

const scoreToColor = (score: number) => {
  if (score >= 90) return '#00C49F'; // Strong green
  if (score >= 80) return '#99CC00'; // Light green
  if (score >= 70) return '#FFBB28'; // Yellow
  if (score >= 60) return '#FF8042'; // Orange
  return '#FF0000'; // Red
};

const CallOpeningClosingQuality: React.FC<CallOpeningClosingQualityProps> = ({ openingQuality, closingQuality }) => {
  const openingScore = openingQuality.SCORE;
  const closingScore = closingQuality.SCORE;

  return (
    <Card className="shadow-sm">
      <Card.Header className="bg-primary text-light">Call Opening and Closing Quality</Card.Header>
      <Card.Body>
      <div style={{ marginTop: '0.3em' }} />
        <h5>Opening Quality</h5>
        <div style={{ marginTop: '0.5em' }} />
      
        <div style={{ backgroundColor: 'var(--progress-bar-background)', borderRadius: '5px', height: '20px', marginBottom: '10px', overflow: 'hidden' }}>
          <div style={{ width: `${openingScore}%`, backgroundColor: scoreToColor(openingScore), height: '100%' }}></div>
        </div>
      
        <div className="d-flex justify-content-between">
          <span>Poor</span>
          
          <span style={{ color: scoreToColor(openingScore) }}>
        
            {scoreToQuality(openingScore)}
          </span>
          <span>Excellent</span>
        </div>
        <p className="mt-2">
          <span style={{ color: scoreToColor(openingScore) }}>
            {scoreToQuality(openingScore)}
          </span>
          : {openingQuality.feedback}
        </p>
<br></br>
        <h5>Closing Quality</h5>
        <div style={{ marginTop: '0.5em' }} />
        <div style={{ backgroundColor: 'var(--progress-bar-background)', borderRadius: '5px', height: '20px', marginBottom: '10px', overflow: 'hidden' }}>
          <div style={{ width: `${closingScore}%`, backgroundColor: scoreToColor(closingScore), height: '100%' }}></div>
        </div>
        <div className="d-flex justify-content-between">
          <span>Poor</span>
          <span style={{ color: scoreToColor(closingScore) }}>
            {scoreToQuality(closingScore)}
          </span>
          <span>Excellent</span>
        </div>
        <p className="mt-2">
          <span style={{ color: scoreToColor(closingScore) }}>
            {scoreToQuality(closingScore)}
          </span>
          : {closingQuality.feedback}
        </p>
      </Card.Body>
    </Card>
  );
};

export default CallOpeningClosingQuality;
