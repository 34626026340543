import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import smallIcon from '../../assets/icons/small.png';
import smallDarkIcon from '../../assets/icons/smallDark.png';
import AnimatedBackground from './AnimatedBackground';
import ThemeToggle from './ThemeToggle';
import { useTheme } from '../../ThemeContext';
import { useAuth } from '../../hooks/AuthContext';
import { loginAPI } from '../../utils/api';


const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { login } = useAuth();
  
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        const data = await loginAPI(username, password);
        login(data.token);
        navigate('/');
      } catch (error) {
        setError('Invalid credentials');
      }
    };

  const logoSrc = theme === 'dark' ? smallIcon : smallDarkIcon;

  return (
    <div className={`login-page ${theme}`}>
      <AnimatedBackground />
      <div className="theme-toggle-container">
        <ThemeToggle />
      </div>
      <div className="login-content">
        <div className="login-container">
          <img src={logoSrc} alt="SaleSim Logo" className="logo" />
          <h1 className="welcome-text">WELCOME BACK</h1>
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <input
                type="text"
                id="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="login-button">LOGIN</button>
          </form>
          <a href="https://www.salesim.com/forgot-password" className="forgot-password">Forgot password?</a>
          <div className="signup-link">
            New to SaleSim? <a href="https://www.salesim.com/signup">Sign up here</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;