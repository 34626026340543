import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ScenarioCard.css';

interface ScenarioCardProps {
  icon: string;
  title: string;
  description: string;
  progress: number;
  link: string;
}

const ScenarioCard: React.FC<ScenarioCardProps> = ({ icon, title, description, progress, link }) => {
  const navigate = useNavigate();

  return (
    <div className="scenario-card" onClick={() => navigate(link)}>
      <div className="scenario-icon">{icon}</div>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="progress-text">{`${progress / 10}/10 Scenarios cleared`}</div>
    </div>
  );
};

export default ScenarioCard;
