import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScenarioCard from './ScenarioCard';
import ThemeToggle from './ThemeToggle';
import AnimatedBackground from './AnimatedBackground';
import './LandingPage.css';
import { useTheme } from '../../ThemeContext';
import { useAuth } from '../../hooks/AuthContext';
import { getAllProgress } from '../../utils/api';

interface Progress {
  type: string;
  cleared_count: number;
  total_count: number;
}

const LandingPage: React.FC = () => {
  const { theme } = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<Record<string, Progress>>({});

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const data = await getAllProgress();
        const progressMap = data.reduce((acc: { [x: string]: any; }, item: { type: string | number; }) => {
          acc[item.type] = item;
          return acc;
        }, {});
        setProgress(progressMap);
      } catch (error) {
        console.error('Error fetching progress:', error);
      }
    };
    fetchProgress();
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const getProgress = (type: string) => {
    const typeProgress = progress[type];
    if (!typeProgress) return 0;
    return Math.round((typeProgress.cleared_count / typeProgress.total_count) * 100);
  };

  return (
    <div className={`landing-page ${theme}`}>
      <AnimatedBackground />
      <div className="container">
        <div className="theme-toggle-container">
          <ThemeToggle />
        </div>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
        <h1>AI Sales Simulator</h1>
        <div className="scenario-grid">
          <ScenarioCard
            icon="🎯"
            title="Master Discovery Call"
            description="Leverage AI to perfect your questioning techniques and uncover customer needs with precision."
            progress={getProgress('Discovery Call')}
            link="/scenarios/Discovery Call"
          />
          <ScenarioCard
            icon="📞"
            title="Crush Cold Calling"
            description="Use AI-powered insights to break the ice and turn cold calls into warm opportunities."
            progress={getProgress('Cold Call')}
            link="/scenarios/Cold Call"
          />
          <ScenarioCard
            icon="🛡️"
            title="Objection Handling"
            description="Train with AI to anticipate and overcome objections with data-driven strategies."
            progress={getProgress('Objection Handling')}
            link="/scenarios/Objection Handling"
          />
          <ScenarioCard
            icon="🤝"
            title="Closing Techniques"
            description="Master AI-enhanced closing strategies to increase your deal closure rate."
            progress={getProgress('Closing Techniques')}
            link="/scenarios/Closing Techniques"
          />
        </div>
        <div className="custom-scenario">
          <h2>Need a Tailored AI Challenge?</h2>
          <button className="custom-button" onClick={() => navigate('/custom-scenario')}>
            Create Custom AI Scenario
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;