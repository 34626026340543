import React, { CSSProperties } from 'react';

interface TipsProps {
  tipsVisible: boolean[];
  tipVisibility: boolean[];
  initialTips: string[];
  setTipVisibility: React.Dispatch<React.SetStateAction<boolean[]>>;
  closeAll: () => void;
}

const Tips: React.FC<TipsProps> = ({ tipsVisible, tipVisibility, initialTips, setTipVisibility, closeAll }) => {
  const dismissTip = (index: number) => {
    setTipVisibility((prevVisibility: boolean[]) => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = false;
      return newVisibility;
    });
  };

  const closeAllTips = () => {
    setTipVisibility([false, false, false, false]);
    closeAll();
  };

  const cardStyle: CSSProperties = {
    maxWidth: '100%',
    margin: 'auto',
    backgroundColor: 'var(--card-background)',
    border: '1px solid var(--border)',
    color: 'var(--text)',
    opacity: 1,
    transition: 'opacity 0.5s ease',
    fontSize: '1rem',
    width: '100%',
    padding: '0.5rem',
    boxSizing: 'border-box',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  };

  const headerStyle: CSSProperties = {
    backgroundColor: 'transparent',
    padding: '0.5rem',
    fontWeight: 'bold',
    color: 'var(--primary)',
  };

  const bodyStyle: CSSProperties = {
    backgroundColor: 'transparent',
    padding: '0.5rem',
  };

  return (
    <>
      {tipVisibility[0] && (
        <div className="card goal mb-2" style={cardStyle}>
          <button className="close-button" onClick={() => dismissTip(0)}>×</button>
          <div className="card-header" style={headerStyle}>Goal</div>
          <div className="card-body" style={bodyStyle}>{initialTips[0]}</div>
        </div>
      )}
      {tipVisibility[1] && (
        <div className="card task mb-2" style={cardStyle}>
          <button className="close-button" onClick={() => dismissTip(1)}>×</button>
          <div className="card-header" style={headerStyle}>Task</div>
          <div className="card-body" style={bodyStyle}>{initialTips[1]}</div>
        </div>
      )}
      {tipVisibility[2] && (
        <div className="card objective mb-2" style={cardStyle}>
          <button className="close-button" onClick={() => dismissTip(2)}>×</button>
          <div className="card-header" style={headerStyle}>Objective</div>
          <div className="card-body" style={bodyStyle}>{initialTips[2]}</div> 
        </div>
      )}
      {tipVisibility[3] && (
        <div className="card after-call-report mb-2" style={cardStyle}>
          <button className="close-button" onClick={() => dismissTip(3)}>×</button>
          <div className="card-header" style={headerStyle}>After Call Report</div>
          <div className="card-body" style={bodyStyle}>{initialTips[3]}</div>
        </div>
      )}
      {tipsVisible.some(tip => tip) && (
        <div className="card close-all mb-2" style={cardStyle}>
          <div className="card-body text-center">
            <button className="btn btn-close-all" onClick={closeAllTips}>Close All</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Tips;
