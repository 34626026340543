import React, { useMemo } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface FillerWord {
  word: string;
  start: number;
  end: number;
}

interface FillerWordCount {
  word: string;
  count: number;
}

interface FillerWordsAnalysisProps {
  fillerWords: FillerWord[];
  totalWords: number;
}

const FillerWordsAnalysis: React.FC<FillerWordsAnalysisProps> = ({ fillerWords, totalWords }) => {
  const fillerWordCounts = useMemo<FillerWordCount[]>(() => {
    const counts: { [key: string]: number } = {};
    fillerWords.forEach(({ word }) => {
      counts[word] = (counts[word] || 0) + 1;
    });
    return Object.entries(counts).map(([word, count]) => ({ word, count }));
  }, [fillerWords]);

  const totalFillerWords = fillerWords.length;
  const uniqueFillerWords = fillerWordCounts.length;

  // Calculate score (0-100)
  const fillerWordPercentage = (totalFillerWords / totalWords) * 100;
  const score = Math.max(0, Math.min(100, 100 - fillerWordPercentage * 2));

  const getScoreColor = (score: number): string => {
    if (score >= 80) return 'success';
    if (score >= 60) return 'warning';
    return 'danger';
  };

  return (
    <Card className="filler-words-analysis">
      <Card.Header className="bg-primary text-white">
        <h5 className="mb-0">Filler Words Analysis</h5>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2 className="mb-0">
              <Badge bg={getScoreColor(score)} style={{ fontSize: '2rem' }}>
                {score.toFixed(0)}
              </Badge>
            </h2>
            <small className="text-muted">Filler Words Score</small>
          </div>
          <div className="text-end">
            <h4>{totalFillerWords}</h4>
            <small className="text-muted">Total Filler Words</small>
          </div>
          <div className="text-end">
            <h4>{uniqueFillerWords}</h4>
            <small className="text-muted">Unique Filler Words</small>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={fillerWordCounts}>
            <XAxis dataKey="word" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>

        <div className="mt-3">
          <h6>Filler Words Used:</h6>
          <div>
            {fillerWordCounts.map(({ word, count }) => (
              <Badge 
                bg="secondary" 
                className="me-2 mb-2" 
                key={word}
                style={{ fontSize: '1rem' }}
              >
                {word}: {count}
              </Badge>
            ))}
          </div>
        </div>

        <div className="mt-3">
          <h6>Tips to Reduce Filler Words:</h6>
          <ul>
            <li>Practice pausing instead of using filler words</li>
            <li>Prepare and rehearse your key points</li>
            <li>Speak more slowly and deliberately</li>
            <li>Record yourself and identify your most common filler words</li>
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FillerWordsAnalysis;