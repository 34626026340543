import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import CallPage from './components/Pages/CallPage';
import AdvancedCallAnalysisPage from './components/Report/AdvancedCallAnalysis';
import LandingPage from './components/Pages/LandingPage';
import Scenarios from './components/Pages/Scenarios';
import ApiStatusChecker from './components/Pages/ApiStatusChecker';
import LoginPage from './components/Pages/LoginPage';
import InterviewResultsTable from './components/Pages/interviewsResultTable';
import { ThemeProvider } from './ThemeContext';
import { AuthProvider } from './hooks/AuthContext';
import ProtectedRoute from './components/Pages/ProtectedRoute';
import './theme.css';
import JobInterviewReport from './components/Pages/JobInterviewReport';
import AIHRInterviewConfig from './components/Pages/AIHRInterviewConfig';
import HRInterviewDashboard from './components/Pages/HrDashboard';

import CVScanner from './components/Pages/CVScanner';
function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <ApiStatusChecker />
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<ProtectedRoute><LandingPage /></ProtectedRoute>} />
            <Route path="/scenarios/:type" element={<ProtectedRoute><Scenarios /></ProtectedRoute>} />
            <Route path="/call/:id" element={<ProtectedRoute><CallPage /></ProtectedRoute>} />
            <Route path="/post-call-analysis" element={<ProtectedRoute><AdvancedCallAnalysisPage /></ProtectedRoute>} />
            <Route path="/interviewResults" element={<ProtectedRoute><InterviewResultsTable /></ProtectedRoute>} />
            <Route path="/job-interview-report" element={<ProtectedRoute><JobInterviewReport /></ProtectedRoute>} />
            <Route path="/aihr-interview-config" element={<ProtectedRoute><AIHRInterviewConfig /></ProtectedRoute>} />
                        <Route path="/dashboard" element={<ProtectedRoute><HRInterviewDashboard /></ProtectedRoute>} />

            <Route path="/interview-results" element={<ProtectedRoute><InterviewResultsTable /></ProtectedRoute>} />
            <Route path="/CV-scanner" element={<ProtectedRoute><CVScanner /></ProtectedRoute>} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;