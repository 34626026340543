import React, { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import { Textarea } from '../ui/textarea';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Slider } from '../ui/sliders';
import { Loader2 } from 'lucide-react';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

interface JobInfo {
  title: string;
  department: string;
  description: string;
  seniorityLevel: string;
}

interface Competencies {
  core: string[];
  technical: string[];
  behavioral: string[];
}

interface InterviewFocus {
  primary: string;
  secondary: string;
}

interface Weights {
  core: number;
  technical: number;
  behavioral: number;
  cultural: number;
}

interface CultureFit {
  description: string;
  questions: string[];
}

interface ApiResponse {
  jobInfo: JobInfo;
  competencies: Competencies;
  experienceLevel: string;
  educationRequirements: string;
  interviewFocus: InterviewFocus;
  redFlags: string[];
  weights: Weights;
  cultureFit: CultureFit;
  selectedQuestions: string[];
}

const Loading: React.FC = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
      <Loader2 className="animate-spin h-12 w-12 text-blue-500 mb-4" />
      <p className="text-lg font-semibold">Generating Configuration...</p>
    </div>
  </div>
);

const AIHRInterviewConfig: React.FC = () => {
  const [promptText, setPromptText] = useState<string>('');
  const [jobInfo, setJobInfo] = useState<JobInfo>({ title: '', department: '', description: '', seniorityLevel: '' });
  const [competencies, setCompetencies] = useState<Competencies>({ core: [''], technical: [''], behavioral: [''] });
  const [experienceLevel, setExperienceLevel] = useState<string>('');
  const [educationRequirements, setEducationRequirements] = useState<string>('');
  const [interviewFocus, setInterviewFocus] = useState<InterviewFocus>({ primary: '', secondary: '' });
  const [redFlags, setRedFlags] = useState<string[]>(['']);
  const [weights, setWeights] = useState<Weights>({ core: 25, technical: 25, behavioral: 25, cultural: 25 });
  const [cultureFit, setCultureFit] = useState<CultureFit>({ description: '', questions: [''] });
  const [questionType, setQuestionType] = useState<'predefined' | 'custom'>('predefined');
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [customQuestions, setCustomQuestions] = useState<string[]>(['']);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const predefinedQuestions = [
    "Can you describe a challenging situation you've faced at work and how you handled it?",
    "What do you consider to be your greatest professional achievement?",
    "How do you stay updated with the latest trends in your field?",
    "Describe a time when you had to work with a difficult team member. How did you manage the situation?",
    "What's your approach to meeting tight deadlines?",
    "How do you handle constructive criticism?",
    "Can you give an example of a time you showed leadership skills?",
    "How do you prioritize your tasks when you have multiple deadlines?",
    "What's your ideal work environment?",
    "Where do you see yourself professionally in five years?"
  ];
  const handleSaveConfiguration = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const config = {
        jobInfo,
        competencies,
        experienceLevel,
        educationRequirements,
        interviewFocus,
        redFlags,
        weights,
        cultureFit,
        selectedQuestions: questionType === 'custom' ? customQuestions : selectedQuestions
      };

      const response = await axios.post(
        `${apiUrl}/api/HRConfigs/save-config`,
        config,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('Configuration saved:', response.data);
      // You might want to show a success message to the user here
    } catch (error) {
      console.error('Error saving configuration:', error);
      setError('Failed to save configuration');
    }
  };

  const handlePromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => setPromptText(e.target.value);

  const handleJobInfoChange = (field: keyof JobInfo, value: string) => setJobInfo({ ...jobInfo, [field]: value });

  const handleCompetencyChange = (type: keyof Competencies, index: number, value: string) => {
    const newCompetencies = { ...competencies };
    newCompetencies[type][index] = value;
    setCompetencies(newCompetencies);
  };

  const addCompetency = (type: keyof Competencies) => {
    setCompetencies({ ...competencies, [type]: [...competencies[type], ''] });
  };

  const handleRedFlagChange = (index: number, value: string) => {
    const newRedFlags = [...redFlags];
    newRedFlags[index] = value;
    setRedFlags(newRedFlags);
  };

  const addRedFlag = () => setRedFlags([...redFlags, '']);

  const handleWeightChange = (type: keyof Weights, value: number[]) => {
    const newWeights = { ...weights, [type]: value[0] };
    setWeights(newWeights);
  };

  const handleCultureFitChange = (field: keyof CultureFit, value: string, index?: number) => {
    if (field === 'questions' && typeof index === 'number') {
      const newQuestions = [...cultureFit.questions];
      newQuestions[index] = value;
      setCultureFit({ ...cultureFit, questions: newQuestions });
    } else {
      setCultureFit({ ...cultureFit, [field]: value });
    }
  };

  const addCultureFitQuestion = () => {
    setCultureFit({ ...cultureFit, questions: [...cultureFit.questions, ''] });
  };

  const handleQuestionTypeChange = (type: 'predefined' | 'custom') => setQuestionType(type);

  const handlePredefinedQuestionChange = (question: string) => {
    setSelectedQuestions(prev => 
      prev.includes(question) ? prev.filter(q => q !== question) : [...prev, question]
    );
  };

  const handleCustomQuestionChange = (index: number, value: string) => {
    const newCustomQuestions = [...customQuestions];
    newCustomQuestions[index] = value;
    setCustomQuestions(newCustomQuestions);
  };

  const addCustomQuestion = () => setCustomQuestions([...customQuestions, '']);

  const removeCustomQuestion = (index: number) => {
    setCustomQuestions(customQuestions.filter((_, i) => i !== index));
  };

  const handleGenerateConfiguration = async () => {
    if (!promptText.trim()) {
      setError("Please paste a job posting before generating the configuration.");
      return;
    }

    setShowLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post<ApiResponse>(
        `${apiUrl}/api/test/generate-config`, 
        { jobPosting: promptText },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const data = response.data;

      setJobInfo(data.jobInfo);
      setCompetencies(data.competencies);
      setExperienceLevel(data.experienceLevel);
      setEducationRequirements(data.educationRequirements);
      setInterviewFocus(data.interviewFocus);
      setRedFlags(data.redFlags);
      setWeights(data.weights);
      setCultureFit(data.cultureFit);
      
      if (data.selectedQuestions && data.selectedQuestions.length > 0) {
        setCustomQuestions(data.selectedQuestions);
        setQuestionType('custom');
      } else {
        setCustomQuestions([]);
        setQuestionType('predefined');
      }

    } catch (err) {
      console.error("Error generating configuration:", err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      {showLoading && <Loading />}
      <h1 className="text-3xl font-bold mb-6 text-primary">AI-Powered HR Interview Configuration</h1>

      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">GPT Automation Prompt</h2>
        </CardHeader>
        <CardContent>
          <Textarea
            placeholder="Paste your LinkedIn job posting here, and I'll generate a tailored interview configuration..."
            value={promptText}
            onChange={handlePromptChange}
            className="w-full h-32"
          />
          <Button className="mt-4" onClick={handleGenerateConfiguration} disabled={showLoading}>
            {showLoading ? 'Generating...' : 'Generate Configuration'}
          </Button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">1. Job Role and Description</h2>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label htmlFor="jobTitle">Job Title</Label>
              <Input id="jobTitle" value={jobInfo.title} onChange={(e: ChangeEvent<HTMLInputElement>) => handleJobInfoChange('title', e.target.value)} />
            </div>
            <div>
              <Label htmlFor="department">Department</Label>
              <Input id="department" value={jobInfo.department} onChange={(e: ChangeEvent<HTMLInputElement>) => handleJobInfoChange('department', e.target.value)} />
            </div>
          </div>
          <div className="mt-4">
            <Label htmlFor="jobDescription">Job Description</Label>
            <Textarea id="jobDescription" value={jobInfo.description} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleJobInfoChange('description', e.target.value)} />
          </div>
          <div className="mt-4">
            <Label htmlFor="seniorityLevel">Seniority Level</Label>
            <Input id="seniorityLevel" value={jobInfo.seniorityLevel} onChange={(e: ChangeEvent<HTMLInputElement>) => handleJobInfoChange('seniorityLevel', e.target.value)} />
          </div>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">2. Key Competencies and Skills</h2>
        </CardHeader>
        <CardContent>
          {(Object.keys(competencies) as Array<keyof Competencies>).map((type) => (
            <div key={type} className="mb-4">
              <Label>{type.charAt(0).toUpperCase() + type.slice(1)} Competencies</Label>
              {competencies[type].map((comp, index) => (
                <Input
                  key={index}
                  value={comp}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleCompetencyChange(type, index, e.target.value)}
                  className="mb-2"
                />
              ))}
              <Button onClick={() => addCompetency(type)}>Add {type.charAt(0).toUpperCase() + type.slice(1)} Competency</Button>
            </div>
          ))}
          <div className="mt-4">
            <Label htmlFor="experienceLevel">Experience Level</Label>
            <Input id="experienceLevel" value={experienceLevel} onChange={(e: ChangeEvent<HTMLInputElement>) => setExperienceLevel(e.target.value)} />
          </div>
          <div className="mt-4">
            <Label htmlFor="educationRequirements">Education Requirements</Label>
            <Input id="educationRequirements" value={educationRequirements} onChange={(e: ChangeEvent<HTMLInputElement>) => setEducationRequirements(e.target.value)} />
          </div>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">3. Interview Focus and Strategy</h2>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <Label htmlFor="primaryFocus">Primary Focus</Label>
            <Input id="primaryFocus" value={interviewFocus.primary} onChange={(e: ChangeEvent<HTMLInputElement>) => setInterviewFocus({...interviewFocus, primary: e.target.value})} />
          </div>
          <div className="mb-4">
            <Label htmlFor="secondaryFocus">Secondary Focus</Label>
            <Input id="secondaryFocus" value={interviewFocus.secondary} onChange={(e: ChangeEvent<HTMLInputElement>) => setInterviewFocus({...interviewFocus, secondary: e.target.value})} />
          </div>
          <div>
            <Label>Red Flags</Label>
            {redFlags.map((flag, index) => (
              <Input
                key={index}
                value={flag}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleRedFlagChange(index, e.target.value)}
                className="mb-2"
              />
            ))}
            <Button onClick={addRedFlag}>Add Red Flag</Button>
          </div>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">4. Evaluation Weighting</h2>
        </CardHeader>
        <CardContent>
          {Object.entries(weights).map(([key, value]) => (
            <div key={key} className="mb-4">
              <Label>{key.charAt(0).toUpperCase() + key.slice(1)} Competencies</Label>
              <Slider
                value={[value]}
                onValueChange={(val: number[]) => handleWeightChange(key as keyof Weights, val)}
                max={100}
                step={1}
              />
              <span>{value}%</span>
            </div>
          ))}
          <div className={`mt-4 ${Object.values(weights).reduce((a, b) => a + b, 0) !== 100 ? 'text-red-500' : ''}`}>
            Total Weight: {Object.values(weights).reduce((a, b) => a + b, 0)}% 
            {Object.values(weights).reduce((a, b) => a + b, 0) !== 100 ? ' (Must equal 100%)' : ''}
          </div>
        </CardContent>
      </Card>
      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">5. Culture Fit Assessment</h2>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <Label htmlFor="companyCulture">Company Culture Description</Label>
            <Textarea
              id="companyCulture"
              value={cultureFit.description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleCultureFitChange('description', e.target.value)}
            />
          </div>
          <div>
            <Label>Culture Fit Questions</Label>
            {cultureFit.questions.map((question, index) => (
              <Input
                key={index}
                value={question}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleCultureFitChange('questions', e.target.value, index)}
                className="mb-2"
              />
            ))}
            <Button onClick={addCultureFitQuestion}>Add Culture Fit Question</Button>
          </div>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-xl font-semibold">6. Interview Questions</h2>
        </CardHeader>
        <CardContent>
          <div className="flex mb-4">
            <Button
              onClick={() => handleQuestionTypeChange('predefined')}
              variant={questionType === 'predefined' ? 'default' : 'outline'}
              className="mr-2"
            >
              Predefined Questions
            </Button>
            <Button
              onClick={() => handleQuestionTypeChange('custom')}
              variant={questionType === 'custom' ? 'default' : 'outline'}
            >
              Custom Questions
            </Button>
          </div>

          {questionType === 'predefined' && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Select Predefined Questions:</h3>
              {predefinedQuestions.map((question, index) => (
                <div key={index} className="flex items-center mb-2">
                  <Checkbox
                    id={`question-${index}`}
                    checked={selectedQuestions.includes(question)}
                    onCheckedChange={() => handlePredefinedQuestionChange(question)}
                  />
                  <label htmlFor={`question-${index}`} className="ml-2">{question}</label>
                </div>
              ))}
            </div>
          )}

          {questionType === 'custom' && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Add Custom Questions:</h3>
              {customQuestions.map((question, index) => (
                <div key={index} className="flex items-center mb-2">
                  <Textarea
                    value={question}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleCustomQuestionChange(index, e.target.value)}
                    className="flex-grow mr-2"
                  />
                  <Button onClick={() => removeCustomQuestion(index)} variant="destructive">Remove</Button>
                </div>
              ))}
              <Button onClick={addCustomQuestion} className="mt-2">Add Custom Question</Button>
            </div>
          )}
        </CardContent>
      </Card>

      <Button className="mt-6" onClick={handleSaveConfiguration}>Save Configuration</Button>    </div>
  );
};

export default AIHRInterviewConfig;