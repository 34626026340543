import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTheme } from '../../ThemeContext';

interface AnimatedElement {
  element: SVGElement;
  x: number;
  y: number;
  speedX: number;
  speedY: number;
  rotation: number;
  rotationSpeed: number;
  radius: number;
}

const AnimatedBackground: React.FC = () => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();
  const [elements, setElements] = useState<AnimatedElement[]>([]);
  const animationRef = useRef<number>();
  const mousePos = useRef({ x: 0, y: 0 });

  const getThemeColors = useCallback(() => {
    return theme === 'dark'
      ? { fill: 'rgba(255, 255, 255, 0.05)', stroke: 'rgba(255, 255, 255, 0.1)' }
      : { fill: 'rgba(52, 152, 219, 0.05)', stroke: 'rgba(52, 152, 219, 0.1)' };
  }, [theme]);

  const createSVGElement = (type: string) => {
    return document.createElementNS('http://www.w3.org/2000/svg', type);
  };

  const createRobotIcon = useCallback(() => {
    const svg = createSVGElement('svg');
    svg.setAttribute('viewBox', '0 0 24 24');
    svg.setAttribute('width', '60');
    svg.setAttribute('height', '60');

    const { fill, stroke } = getThemeColors();

    const head = createSVGElement('rect');
    head.setAttribute('x', '7');
    head.setAttribute('y', '3');
    head.setAttribute('width', '10');
    head.setAttribute('height', '10');
    head.setAttribute('rx', '2');

    const body = createSVGElement('rect');
    body.setAttribute('x', '5');
    body.setAttribute('y', '13');
    body.setAttribute('width', '14');
    body.setAttribute('height', '8');
    body.setAttribute('rx', '2');

    const leftEye = createSVGElement('circle');
    leftEye.setAttribute('cx', '10');
    leftEye.setAttribute('cy', '7');
    leftEye.setAttribute('r', '1.5');

    const rightEye = createSVGElement('circle');
    rightEye.setAttribute('cx', '14');
    rightEye.setAttribute('cy', '7');
    rightEye.setAttribute('r', '1.5');

    const antenna = createSVGElement('line');
    antenna.setAttribute('x1', '12');
    antenna.setAttribute('y1', '3');
    antenna.setAttribute('x2', '12');
    antenna.setAttribute('y2', '1');

    svg.appendChild(head);
    svg.appendChild(body);
    svg.appendChild(leftEye);
    svg.appendChild(rightEye);
    svg.appendChild(antenna);

    svg.style.fill = fill;
    svg.style.stroke = stroke;

    return svg;
  }, [getThemeColors]);

  const createChatBubble = useCallback(() => {
    const svg = createSVGElement('svg');
    svg.setAttribute('viewBox', '0 0 24 24');
    svg.setAttribute('width', '60');
    svg.setAttribute('height', '60');

    const { fill, stroke } = getThemeColors();

    const path = createSVGElement('path');
    path.setAttribute('d', 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z');

    svg.appendChild(path);
    svg.style.fill = fill;
    svg.style.stroke = stroke;

    return svg;
  }, [getThemeColors]);

  const initializeElements = useCallback(() => {
    const newElements: AnimatedElement[] = [];
    for (let i = 0; i < 15; i++) {
      const element = i % 2 === 0 ? createRobotIcon() : createChatBubble();
      newElements.push({
        element,
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        speedX: (Math.random() - 0.5) * 2,
        speedY: (Math.random() - 0.5) * 2,
        rotation: Math.random() * 360,
        rotationSpeed: (Math.random() - 0.5) * 2,
        radius: 30, // Assuming the icons are roughly 60x60
      });
    }
    setElements(newElements);
  }, [createRobotIcon, createChatBubble]);

  const checkCollision = (a: AnimatedElement, b: AnimatedElement) => {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance < (a.radius + b.radius);
  };

  const resolveCollision = (a: AnimatedElement, b: AnimatedElement) => {
    const dx = b.x - a.x;
    const dy = b.y - a.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Normal vector
    const nx = dx / distance;
    const ny = dy / distance;

    // Tangent vector
    const tx = -ny;
    const ty = nx;

    // Dot product tangent
    const dpTanA = a.speedX * tx + a.speedY * ty;
    const dpTanB = b.speedX * tx + b.speedY * ty;

    // Dot product normal
    const dpNormA = a.speedX * nx + a.speedY * ny;
    const dpNormB = b.speedX * nx + b.speedY * ny;

    // Conservation of momentum in 1D
    const m1 = dpNormB;
    const m2 = dpNormA;

    // Update speeds
    a.speedX = tx * dpTanA + nx * m1;
    a.speedY = ty * dpTanA + ny * m1;
    b.speedX = tx * dpTanB + nx * m2;
    b.speedY = ty * dpTanB + ny * m2;
  };

  const updateElementPositions = useCallback(() => {
    setElements(prevElements => {
      const newElements = prevElements.map(el => {
        let newX = el.x + el.speedX;
        let newY = el.y + el.speedY;
        let newRotation = el.rotation + el.rotationSpeed;

        // Cursor interaction
        const dx = newX - mousePos.current.x;
        const dy = newY - mousePos.current.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 100) {
          const angle = Math.atan2(dy, dx);
          const pushForce = (100 - distance) / 100; // Stronger push when closer
          newX += Math.cos(angle) * pushForce * 2;
          newY += Math.sin(angle) * pushForce * 2;
        }

        // Boundary checks
        if (newX < el.radius || newX > window.innerWidth - el.radius) {
          el.speedX *= -1;
          newX = Math.max(el.radius, Math.min(newX, window.innerWidth - el.radius));
        }
        if (newY < el.radius || newY > window.innerHeight - el.radius) {
          el.speedY *= -1;
          newY = Math.max(el.radius, Math.min(newY, window.innerHeight - el.radius));
        }

        return {
          ...el,
          x: newX,
          y: newY,
          rotation: newRotation
        };
      });

      // Check and resolve collisions
      for (let i = 0; i < newElements.length; i++) {
        for (let j = i + 1; j < newElements.length; j++) {
          if (checkCollision(newElements[i], newElements[j])) {
            resolveCollision(newElements[i], newElements[j]);
          }
        }
      }

      return newElements;
    });
  }, []);

  const animate = useCallback(() => {
    updateElementPositions();
    animationRef.current = requestAnimationFrame(animate);
  }, [updateElementPositions]);

  useEffect(() => {
    if (elements.length === 0) {
      initializeElements();
    }

    animate();

    const handleMouseMove = (e: MouseEvent) => {
      mousePos.current = { x: e.clientX, y: e.clientY };
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [initializeElements, animate]);

  useEffect(() => {
    const { fill, stroke } = getThemeColors();
    elements.forEach(el => {
      el.element.style.fill = fill;
      el.element.style.stroke = stroke;
    });
  }, [elements, getThemeColors]);

  useEffect(() => {
    if (backgroundRef.current) {
      backgroundRef.current.innerHTML = '';
      elements.forEach(el => {
        el.element.style.position = 'absolute';
        el.element.style.left = `${el.x}px`;
        el.element.style.top = `${el.y}px`;
        el.element.style.transform = `rotate(${el.rotation}deg)`;
        backgroundRef.current?.appendChild(el.element);
      });
    }
  }, [elements]);

  return <div className="background-animation" ref={backgroundRef}></div>;
};

export default AnimatedBackground;