import React, { useState } from 'react';

const CVScanner: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleScan = () => {
    // Implement CV scanning and question generation logic here
    console.log('Scanning CV and generating questions...');
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>CV Scanner and Question Generator</h1>
        <div style={styles.content}>
          <div style={styles.uploadContainer}>
            <button 
              onClick={() => document.getElementById('file-upload')?.click()} 
              style={styles.uploadButton}
            >
              Upload CV (PDF)
            </button>
            <input
              id="file-upload"
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              style={styles.fileInput}
            />
            {file && <span style={styles.fileName}>{file.name}</span>}
          </div>
          <button
            onClick={handleScan}
            style={{...styles.scanButton, ...(file ? {} : styles.disabledButton)}}
            disabled={!file}
          >
            Scan CV and Generate Questions
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    width: '100%',
    maxWidth: '400px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center' as const,
    marginBottom: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '20px',
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  uploadButton: {
    backgroundColor: '#3490dc',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
  fileName: {
    fontSize: '14px',
  },
  scanButton: {
    backgroundColor: '#38a169',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
  },
  disabledButton: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
};

export default CVScanner;