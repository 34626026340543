import React from 'react';

interface HeaderProps {
  isRecording: boolean;
  isWaitingForReply: boolean;
  isTalking: boolean;
}

const Header: React.FC<HeaderProps> = ({ isRecording, isWaitingForReply, isTalking }) => (
  <div className="row text-center my-3">
    <div className={`col-3 ${isRecording ? 'bg-danger' : 'bg-secondary'} rounded-circle mx-auto`} style={{ height: '20px', width: '20px' }} />
    <div className="col-3">{isRecording ? <p>Recording...</p> : <p>Not Recording</p>}</div>
    <div className="col-3">{isWaitingForReply ? <p>Waiting for Reply...</p> : <p>Not Waiting for Reply</p>}</div>
    <div className="col-3">{isTalking ? <p>Talking...</p> : <p>Not Talking</p>}</div>
  </div>
);

export default Header;
