export const getScoreClass = (score: number): string => {
  if (score >= 90) return 'excellent';
  if (score >= 75) return 'good';
  if (score >= 50) return 'average';
  return 'poor';
};

export const getScoreColor = (score: number) => {
  if (score >= 80) return '#00C49F'; // Green for scores 90 and above
  if (score >= 65) return '#FFBB28'; // Yellow for scores 75 to 89
  if (score >= 50) return '#FF8042'; // Orange for scores 50 to 74
  return '#d9534f'; // Red for scores below 50
};

export const triggerConfetti = (e: any, score: number): void => {
  if (score >= 75) {
    // Your confetti logic here
  }
};
