import React, { useState } from 'react';
import { BarChart, Bar, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button } from 'react-bootstrap';
import './TalkingTimeChart.css';

interface CallDynamicsData {
  name: string;
  time: number;
}

interface TalkingTimeChartProps {
  data: CallDynamicsData[];
}

const COLORS = ['#8884d8', '#82ca9d'];

const formatTime = (time: number) => {
  if (time < 60) {
    return `${time}s`;
  } else {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} minutes`;
  }
};

const calculatePercentage = (time: number, total: number) => {
  return `${((time / total) * 100).toFixed(2)}%`;
};

const TalkingTimeChart: React.FC<TalkingTimeChartProps> = ({ data }) => {
  const [chartType, setChartType] = useState<'bar' | 'pie'>('bar');

  const toggleChartType = () => {
    setChartType(chartType === 'bar' ? 'pie' : 'bar');
  };

  const totalTalkingTime = data.reduce((total, entry) => total + entry.time, 0);

  return (
    <div>
      <div className="chart-header">
    
        <Button variant="primary" onClick={toggleChartType} className="toggle-button">
          Change Graph
        </Button>
      </div>
      {chartType === 'bar' && (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              formatter={(value: number) => `${formatTime(value)} (${calculatePercentage(value, totalTalkingTime)})`}
            />
            <Legend />
            <Bar dataKey="time" fill={COLORS[0]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {chartType === 'pie' && (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              dataKey="time"
              nameKey="name"
              cx="50%"
              cy="48%"
              outerRadius={120}
              fill="#8884d8"
              label={({ name, time }) => `${name}: ${formatTime(time)} (${calculatePercentage(time, totalTalkingTime)})`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: number) => `${formatTime(value)} (${calculatePercentage(value, totalTalkingTime)})`}
            />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default TalkingTimeChart;
