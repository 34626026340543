import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { fetchScenariosAPI, getProgressByType } from '../../utils/api';
import ThemeToggle from './ThemeToggle';
import { useTheme } from '../../ThemeContext';
import './Scenarios.css';

interface Scenario {
  id: number;
  goal: string;
  task: string;
}

interface ConfettiAnimation {
  id: number;
  x: number;
  y: number;
}

interface Progress {
  scenario_id: number;
  goal: string;
  task: string;
  best_score: number | null;
  attempt_count: number;
  cleared: boolean;
}

const Scenarios: React.FC = () => {
  const { type } = useParams<{ type: string }>();
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [progress, setProgress] = useState<Progress[]>([]);
  const [loading, setLoading] = useState(true);
  const [confettiAnimations, setConfettiAnimations] = useState<ConfettiAnimation[]>([]);
  const navigate = useNavigate();
  const { theme } = useTheme();



  useEffect(() => {
    const fetchData = async () => {
      try {
        const scenarioType = type || 'default';
        const [scenariosData, progressData] = await Promise.all([
          fetchScenariosAPI(scenarioType),
          getProgressByType(scenarioType)
        ]);
        setScenarios(scenariosData);
        setProgress(progressData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  const handleScenarioClick = (id: number) => {
    navigate(`/call/${id}`);
  };

  const startRandomUnclearedScenario = () => {
    const unclearedScenarios = scenarios.filter(scenario => 
      !progress.find(p => p.scenario_id === scenario.id && p.cleared)
    );
    if (unclearedScenarios.length > 0) {
      const randomScenario = unclearedScenarios[Math.floor(Math.random() * unclearedScenarios.length)];
      handleScenarioClick(randomScenario.id);
    }
  };

  const triggerConfetti = useCallback((e: React.MouseEvent) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const newAnimation: ConfettiAnimation = {
      id: Date.now(),
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
    setConfettiAnimations(prevAnimations => [...prevAnimations, newAnimation]);
  }, []);

  const handleMouseEnter = (e: React.MouseEvent, score: number) => {
    if (score === 100) {
      triggerConfetti(e);
    }
  };

  const handleConfettiComplete = (id: number) => {
    setConfettiAnimations(prevAnimations => prevAnimations.filter(animation => animation.id !== id));
  };

  const clearedCount = progress.filter(p => p.cleared).length;
  const averageScore = progress.reduce((sum, p) => sum + (p.best_score || 0), 0) / progress.filter(p => p.best_score !== null).length || 0;
  const bestPerformance = Math.max(...progress.map(p => p.best_score || 0), 0);


  return (
    <Container fluid className={`main-container ${theme === 'dark' ? 'bg-dark text-light' : 'bg-light text-dark'}`}>
      {confettiAnimations.map((animation) => (
        <Confetti
          key={animation.id}
          numberOfPieces={200}
          gravity={0.02}
          width={window.innerWidth}
          height={document.documentElement.scrollHeight}
          confettiSource={{
            x: animation.x - 200,
            y: animation.y - 200,
            w: 400,
            h: 400,
          }}
          initialVelocityY={1}
          recycle={false}
          onConfettiComplete={() => handleConfettiComplete(animation.id)}
        />
      ))}
      <ThemeToggle />
    
      <Row className="mb-5 text-center">
        <Col>
          <h1 className="display-4">{type?.replace('-', ' ').toUpperCase()} SCENARIOS</h1>
        </Col>
      </Row>

      <div className="progress-container">
        <div className="progress-item">
          <div className="progress-label">Scenarios Cleared</div>
          <div className="progress-value">
            <span id="cleared-count">{clearedCount}</span> / {scenarios.length}
          </div>
          <div className="progress-bar">
            <div className="progress-fill" style={{ width: `${(clearedCount / scenarios.length) * 100}%` }}></div>
          </div>
        </div>
        <div className="progress-item">
          <div className="progress-label">Average Score</div>
          <div className="progress-value" id="avg-score">
            {averageScore.toFixed(2)}
          </div>
        </div>
        <div className="progress-item">
          <div className="progress-label">Best Performance</div>
          <div
            className={`progress-value ${bestPerformance === 100 ? 'score-animated' : ''}`}
            id="best-performance"
            onMouseEnter={(e) => handleMouseEnter(e, bestPerformance)}
          >
            {bestPerformance}
          </div>
        </div>
      </div>

      <div className="random-scenario mb-4">
        <button className="random-button" onClick={startRandomUnclearedScenario}>
          Start Random Uncleared Scenario
        </button>
      </div>
    
      {loading ? (
        <Row className="mb-5 justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="text-center">Loading Scenarios...</p>
        </Row>
      ) : (
        <div className="scenario-grid">
        {scenarios.map((scenario) => {
          const scenarioProgress = progress.find(p => p.scenario_id === scenario.id);
          const isAttempted = scenarioProgress && scenarioProgress.attempt_count > 0;
          const isCleared = scenarioProgress?.cleared || false;
          const bestScore = scenarioProgress ? scenarioProgress.best_score : null;
          const capitalizedGoal = scenario.goal.charAt(0).toUpperCase() + scenario.goal.slice(1);
          return (
            <div
              key={scenario.id}
              className="scenario-card"
              onClick={() => handleScenarioClick(scenario.id)}
              onMouseEnter={(e) => bestScore !== null && handleMouseEnter(e, bestScore)}
            >
              <h3>{capitalizedGoal}</h3>
              <p>{scenario.task}</p>
              <button 
                className="start-button" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleScenarioClick(scenario.id);
                }}
              >
                {isAttempted ? 'Retry Scenario' : 'Start Scenario'}
              </button>
              <div className="scenario-progress">
                <div className="progress-bar">
                  <div className={`progress-fill ${isCleared ? 'cleared' : ''}`} style={{ width: isCleared ? '100%' : '0%' }}></div>
                </div>
                <div className="progress-label">{isCleared ? 'Cleared' : 'Not Cleared'}</div>
              </div>
              {isAttempted && bestScore !== null && (
                <div className="scenario-stats">
                  <div
                    className={`best-score ${bestScore === 100 ? 'perfect-score shine' : bestScore >= 75 ? 'score-green' : bestScore >= 50 ? 'score-yellow' : 'score-red'}`}
                  >
                    Best Score: {bestScore}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      )}
    </Container>
  );
};

export default Scenarios;