import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import RequestHistory from './RequestHistory';
import Tips from '../Report/Tips';
import useAudioRecorder from '../../hooks/useAudioRecorder';
import useKeyHandlers from '../../hooks/useKeyHandlers';
import './CallPage.css';
import { startCallAPI } from '../../utils/api';
import { useTheme } from '../../ThemeContext';

const CallPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const scenarioId = id ? parseInt(id, 10) : 0;

  const [isInCall, setIsInCall] = useState(false);
  const [requestHistory, setRequestHistory] = useState<any[]>([]);
  const [chatText, setChatText] = useState('');
  const [showHeader, setShowHeader] = useState(false);
  const [isIntroSkipped, setIsIntroSkipped] = useState(false);
  const [showSkipIntro, setShowSkipIntro] = useState(false);
  const [showTips, setShowTips] = useState(true);
  const [canTalk, setCanTalk] = useState(false);
  const [tipsSeenOrSkipped, setTipsSeenOrSkipped] = useState(false);
  const [callStartTime, setCallStartTime] = useState<Date | null>(null);
  const [callDuration, setCallDuration] = useState('00:00:00');
  const [tipsVisible, setTipsVisible] = useState<boolean[]>([false, false, false, false]);
  const [callStarted, setCallStarted] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speakingDuration, setSpeakingDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [analysisReport, setAnalysisReport] = useState<string | null>(null);
  const [isWarningVisible, setIsWarningVisible] = useState(false); // Added for interruption warning
  const [showRestartButton, setShowRestartButton] = useState(false); // Added for restart button visibility
  const { theme } = useTheme();
  const navigate = useNavigate();

  const {
    isRecording,
    isWaitingForReply,
    isTalking,
    initialTips,
    tipVisibility,
    audioRef,
    clearTipTimeouts,
    setTipVisibility,
    handleDataAvailable,
    handleStop,
    startRecording,
    stopRecording,
    startCall,
    skipIntro,
    endCall,
    updateRequestHistory,
    setIsWaitingForReply,
  } = useAudioRecorder({
    setIsInCall,
    setCanTalk,
    setShowSkipIntro,
    setShowTips,
    setRequestHistory,
    setChatText,
    setShowHeader,
    setIsIntroSkipped,
    setTipsSeenOrSkipped,
    setTipsVisible,
  });

  const triggerWarning = () => {
    setIsWarningVisible(true);
    setTimeout(() => {
      setIsWarningVisible(false);
    }, 1000); // Warning disappears after 1 second
  };

  useKeyHandlers({
    isRecording,
    isInCall,
    canTalk,
    startRecording,
    stopRecording,
    setIsSpeaking,
    setSpeakingDuration,
    isTalking, // Pass isTalking to useKeyHandlers
    triggerWarning, // Pass triggerWarning to useKeyHandlers
  });

  const closeAllTips = () => {
    setTipVisibility([false, false, false, false]);
    setTipsVisible([false, false, false, false]);
    setCallStarted(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isInCall) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else if (!isInCall && interval) {
      clearInterval(interval);
      setTimer(0);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isInCall]);

  useEffect(() => {
    if (tipVisibility.every((tip) => tip === false)) {
      setCallStarted(true);
    }
  }, [tipVisibility]);

  useEffect(() => {
    if (callStarted) {
      const duration = new Date(timer * 1000).toISOString().substr(11, 8);
      setCallDuration(duration);
    }
  }, [timer, callStarted]);

  const toggleTipsVisibility = () => {
    clearTipTimeouts();
    if (tipsVisible.some((tip) => tip)) {
      setTipVisibility([false, false, false, false]);
    } else {
      setTipVisibility([true, true, true, true]);
    }
    setTipsVisible(tipsVisible.map((tip) => !tip));

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const handleStartCall = async (scenarioId: number = 0) => {
    console.log('Start call button clicked with scenarioId:', scenarioId);

    try {
      const { scenario, audioBlob } = await startCallAPI(scenarioId);
      console.log('Received scenario:', scenario);
      await startCall(scenario, audioBlob);
      setCallStartTime(new Date());
      setIsInCall(true);
      setShowRestartButton(true); // Show the restart button after starting a call
    } catch (error) {
      console.error('Error starting call:', error);
      setIsInCall(false);
    }
  };

  const handleEndCall = async () => {
    toast.info('Generating post-call analysis report...', { autoClose: false });
    try {
      const report = await endCall();
      toast.dismiss();
      if (report && report.analysis) {
        setLoading(true);
        setAnalysisReport(report.analysis);
        setShowModal(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        console.error('Report or report.analysis is undefined');
        toast.error('Failed to generate post-call analysis report.');
      }
    } catch (error) {
      toast.dismiss();
      console.error('Error generating post-call analysis report:', error);
      toast.error('Failed to generate post-call analysis report.');
    }
    setCallStartTime(null);
    setIsInCall(false);
    setCallDuration('00:00:00');
    setTimer(0);
    setChatText(''); // Clear chat text when ending the call
    setShowRestartButton(false); // Hide the restart button when call ends
  };

  const handleEndCallWithoutReport = async () => {
    try {
      await endCall(); // End the call without generating the report
      setCallStartTime(null);
      setIsInCall(false);
      setCallDuration('00:00:00');
      setTimer(0);
      setChatText(''); // Clear chat text when ending the call
      setShowRestartButton(false); // Hide the restart button when call ends
    } catch (error) {
      console.error('Error ending call without report:', error);
      toast.error('Failed to end the call.');
    }
  };

  const handleConfirmEndCall = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleCancelEndCall = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmEndCallAction = () => {
    setShowConfirmationModal(false);
    handleEndCall();
  };

  const handleGoToAnalysis = () => {
    setShowModal(false);
    navigate('/post-call-analysis', { state: { analysis: analysisReport } });
  };

  const handleRestartCall = async () => {
    console.log('Restarting call...');
    if (isInCall) {
      console.log('Ending current call...');
      await handleEndCallWithoutReport(); // End the current call without generating the report
      console.log('Call ended, now starting new call...');
    }
    console.log('Starting new call...');
    await handleStartCall(scenarioId); // Start a new call
    setChatText(''); // Clear chat text when restarting the call
    console.log('New call started');
  };

  return (
    <div className={`mainPage container-fluid ${theme === 'dark' ? 'bg-dark text-light' : 'bg-light text-dark'}`}>
      <Header isRecording={isRecording} isWaitingForReply={isWaitingForReply} isTalking={isTalking} />
    
      <div className="row text-center my-3">
        <p>Press Space to Talk</p>
      </div>
      <div className="row text-center my-3">
        <div className={`col-12 ${isInCall ? 'text-success' : 'text-danger'}`}>
          {isInCall ? `In Call - Duration: ${callDuration}` : 'Not in Call'}
        </div>
      </div>
      <RequestHistory requestHistory={requestHistory} />
      <div className="fixed-top text-right my-3" style={{ right: '20px', left: 'auto' }}>
        {showHeader && <h3 className="fade-in">Scenario Overview</h3>}
        <Tips
          tipsVisible={tipsVisible}
          tipVisibility={tipVisibility}
          initialTips={initialTips}
          setTipVisibility={setTipVisibility}
          closeAll={closeAllTips}
        />
      </div>
      <div className="row row-centered my-5">
        <div className="col col-centered">
        <div className={`ball rounded-circle ${isSpeaking ? 'speaking' : ''} ${isWarningVisible ? 'warning' : ''}`}>
          <div className="muted-icon" style={{ display: isSpeaking ? 'none' : 'block' }}>
              <FontAwesomeIcon icon={faMicrophoneSlash} size="2x" />
          </div>
          {isWarningVisible ? (
              <div className="warning-message">
                  ⚠️ Don&apos;t interrupt the client ⚠️
              </div>
          ) : (
              <>
                  {isSpeaking ? 'Speaking' : isWaitingForReply ? 'Waiting' : canTalk ? 'Press SPACE to talk' : 'Listening'}
              </>
          )}
        </div>
        </div>
        <div className="col col-centered">
          <div className={`ball rounded-circle ${isTalking ? 'talking' : ''}`} style={{ height: '310px', width: '310px' }}>
            <div className="subtitle-container">
              {isWaitingForReply ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                <p>{chatText}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {showSkipIntro && (
        <div className="row text-center my-3">
          <div className="col-12">
            <button className="btn btn-warning" onClick={skipIntro}>
              Skip Scenario Intro
            </button>
          </div>
        </div>
      )}
      {tipsSeenOrSkipped && (
        <div className="row text-center my-3">
          <div className="col-12">
            <button className="btn btn-info btn-sm" onClick={toggleTipsVisibility}>
              {tipsVisible.some((tip) => tip) ? 'Hide Scenario Tips' : 'Show Scenario Tips'}
            </button>
          </div>
        </div>
      )}
      <div className="fixed-bottom text-center my-3">
        <button
          className={`btn col-12 ${isInCall ? 'btn-danger' : 'btn-success'}`}
          onClick={isInCall ? handleConfirmEndCall : () => handleStartCall(scenarioId)}
        >
          {isInCall ? 'Stop Call' : 'Start Call'}
        </button>
        {isInCall && (
          <button
            className="btn btn-warning mt-3 col-12"
            onClick={handleRestartCall}
          >
            Restart Call
          </button>
        )}
      </div>
      <ToastContainer />

      {/* Stop Call Confirmation Modal */}
      <Modal show={showConfirmationModal} onHide={handleCancelEndCall} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm End Call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to end the call?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelEndCall}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmEndCallAction}>
            End Call
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Analysis Report Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Post-Call Analysis Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p>Loading report...</p>
            </div>
          ) : (
            <Button variant="primary" onClick={handleGoToAnalysis}>
              Go to Analysis Report
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CallPage;
