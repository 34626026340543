import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getScoreColor, triggerConfetti } from '../../utils/ScoreUtils';
import QuestionTypeDistributionChart from './QuestionTypeDistributionChart';
import CallOpeningClosingQuality from './CallOpeningClosingQuality';
import WordUsageAnalysisChart from './WordUsageAnalysisChart';
import SentimentAnalysisChart from './SentimentAnalysisChart';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdvancedCallAnalysis.css';
import { useInView } from 'react-intersection-observer';
import TalkingTimeChart from './TalkingTimeChart';
import FillerWordsAnalysis from './FillerWordAnalysis';

const COLORS = ['#00C49F', '#FF8042', '#FFBB28', '#0088FE'];

// Define the types here instead of importing them
interface QualityFeedback {
  SCORE: number;
  feedback: string;
}

interface SentimentData {
  time: string;
  userSentiment: number | null;
  clientSentiment: number | null;
}

interface FillerWord {
  word: string;
  start: number;
  end: number;
}

interface CallLogEntry {
  speaker: 'USER' | 'CLIENT';
  message: string;
  feedback?: string;
  sentiment?: number;
}

interface KeyObjective {
  completed: boolean;
  objective: string;
  feedback: string;
}

interface QuestionDistribution {
  count: number;
  percentage: number;
}

interface LanguageUsage {
  positive: number;
  negative: number;
}

interface CallScore {
  score: number;
  feedback: string;
}

interface CallDynamics {
  userTalkingTime: number;
  assistantTalkingTime: number;
}

interface AnalysisData {
  score?: number;
  subtitle?: string;
  overall_feedback?: string;
  key_objectives?: KeyObjective[];
  call_log_feedback?: CallLogEntry[];
  question_distribution?: Record<string, QuestionDistribution>;
  language_usage?: LanguageUsage;
  call_opening_score?: CallScore;
  call_closing_score?: CallScore;
  callDynamics?: CallDynamics;
  fillerWords?: FillerWord[];
}

const AdvancedCallAnalysisPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [analysisData, setAnalysisData] = useState<AnalysisData | null>(null);
  const [darkMode, setDarkMode] = useState(false);

  // Visibility flags
  const [wordUsageBuilt, setWordUsageBuilt] = useState(false);
  const [questionTypeBuilt, setQuestionTypeBuilt] = useState(false);
  const [callQualityBuilt, setCallQualityBuilt] = useState(false);
  const [sentimentAnalysisBuilt, setSentimentAnalysisBuilt] = useState(false);

  const { ref: wordUsageRef, inView: wordUsageInView } = useInView();
  const { ref: questionTypeRef, inView: questionTypeInView } = useInView();
  const { ref: callQualityRef, inView: callQualityInView } = useInView();
  const { ref: sentimentAnalysisRef, inView: sentimentAnalysisInView } = useInView();

  useEffect(() => {
    const state = location.state as { analysis: AnalysisData } | undefined;
    if (state?.analysis) {
      setAnalysisData(state.analysis);
    }
  }, [location.state]);

  useEffect(() => {
    document.body.className = darkMode ? 'dark-mode' : '';
  }, [darkMode]);

  useEffect(() => {
    if (wordUsageInView && !wordUsageBuilt) setWordUsageBuilt(true);
    if (questionTypeInView && !questionTypeBuilt) setQuestionTypeBuilt(true);
    if (callQualityInView && !callQualityBuilt) setCallQualityBuilt(true);
    if (sentimentAnalysisInView && !sentimentAnalysisBuilt) setSentimentAnalysisBuilt(true);
  }, [wordUsageInView, questionTypeInView, callQualityInView, sentimentAnalysisInView]);

  if (!analysisData) {
    return (
      <Container className="post-call-analysis-page mt-5">
        <h1 className="text-center mb-4">Post Call Analysis Report</h1>
        <p className="text-center">No analysis data available.</p>
      </Container>
    );
  }

  const { 
    score, 
    subtitle, 
    overall_feedback, 
    key_objectives, 
    call_log_feedback, 
    question_distribution, 
    language_usage, 
    call_opening_score, 
    call_closing_score,
    callDynamics,
    fillerWords
  } = analysisData;

  const sentimentAnalysis: SentimentData[] = call_log_feedback?.map((entry, index) => ({
    time: `Message ${index + 1}`,
    userSentiment: entry.speaker === 'USER' ? entry.sentiment ?? null : null,
    clientSentiment: entry.speaker === 'CLIENT' ? entry.sentiment ?? null : null,
  })).filter((entry): entry is SentimentData => entry.userSentiment !== null || entry.clientSentiment !== null) ?? [];

  const wordUsageData = language_usage ? [
    { name: 'positive', value: language_usage.positive, color: '#00C49F' },
    { name: 'negative', value: language_usage.negative, color: '#FF8042' }
  ] : [];

  const questionTypeData = question_distribution ? Object.entries(question_distribution).map(([key, value], index) => ({
    name: key.replace('_', ' '),
    count: value.count,
    percentage: value.percentage,
    color: COLORS[index % COLORS.length]
  })) : [];

  const openingQuality: QualityFeedback | undefined = call_opening_score ? {
    SCORE: call_opening_score.score,
    feedback: call_opening_score.feedback
  } : undefined;

  const closingQuality: QualityFeedback | undefined = call_closing_score ? {
    SCORE: call_closing_score.score,
    feedback: call_closing_score.feedback
  } : undefined;

  return (
    <Container className="post-call-analysis-page mt-5">
      <h1 className="text-center mb-4">Advanced Call Analysis</h1>
      {subtitle && <p className="text-center">{subtitle}</p>}

      {score !== undefined && (
        <Row className="mb-4">
          <Col className="text-center">
            <div className="score-container">
              <h2
                className="score"
                style={{ color: getScoreColor(score) }}
                onMouseEnter={(e) => triggerConfetti(e, score)}
              >
                {score}%
              </h2>
              {subtitle && <p className="subtitle" style={{ color: getScoreColor(score) }}>{subtitle}</p>}
              {overall_feedback && <p className="overall-feedback">{overall_feedback}</p>}
            </div>
          </Col>
        </Row>
      )}

      <div className="tabs-section">
        <Tabs>
          <TabList className="tab-list">
            {key_objectives && <Tab className="tab" selectedClassName="active">Key Objectives</Tab>}
            {overall_feedback && <Tab className="tab" selectedClassName="active">AI Feedback</Tab>}
            {call_log_feedback && <Tab className="tab" selectedClassName="active">Call Log</Tab>}
          </TabList>

          {key_objectives && (
            <TabPanel>
              <div className="tab-content">
                <h2>Key Objectives</h2>
                <ul className="objective-list">
                  {key_objectives.map((objective, index) => (
                    <li key={index} className="objective-item">
                      <span className={`checkmark ${objective.completed ? 'success' : 'partial'}`}>
                        {objective.completed ? '✅' : '❌'}
                      </span>
                      <div className="objective-content">
                        <div className="objective-title">{objective.objective}</div>
                        <div className="objective-feedback">{objective.feedback}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </TabPanel>
          )}

          {overall_feedback && (
            <TabPanel>
              <div className="tab-content">
                <h2 className="section-title ai-feedback-title">AI Feedback and Analysis</h2>
                <div className="ai-feedback">
                  <p>{overall_feedback}</p>
                </div>
              </div>
            </TabPanel>
          )}

          {call_log_feedback && (
            <TabPanel>
              <div className="tab-content">
                <h2 className="section-title call-log-title">Call Log with Feedback</h2>
                <div className="call-log">
                  {call_log_feedback.map((entry, index) => (
                    <div key={index} className="call-log-entry">
                      <span className={`speaker ${entry.speaker === 'USER' ? 'agent' : 'customer'}`}>
                        {entry.speaker === 'USER' ? 'Agent' : 'Customer'}:
                      </span>
                      {entry.message}
                      {entry.feedback && <div className="feedback">{entry.feedback}</div>}
                      {entry.sentiment !== undefined && <div className="sentiment">Sentiment: {entry.sentiment}</div>}
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
          )}
        </Tabs>
      </div>

      <div className="graphs-section">
        <Row className="mb-3">
          {wordUsageData.length > 0 && (
            <Col md={6} ref={wordUsageRef}>
              {wordUsageBuilt && (
                <Card className="shadow-sm">
                  <Card.Header className="bg-info text-light">Word Usage Analysis</Card.Header>
                  <Card.Body>
                    <WordUsageAnalysisChart data={wordUsageData} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          )}
          {questionTypeData.length > 0 && (
            <Col md={6} ref={questionTypeRef}>
              {questionTypeBuilt && (
                <Card className="shadow-sm">
                  <Card.Header className="bg-primary text-light">Question Type Distribution</Card.Header>
                  <Card.Body>
                    <QuestionTypeDistributionChart data={questionTypeData} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          {(openingQuality && closingQuality) && (
            <Col md={6} ref={callQualityRef}>
              {callQualityBuilt && (
                <CallOpeningClosingQuality
                  openingQuality={openingQuality}
                  closingQuality={closingQuality}
                />
              )}
            </Col>
          )}
          {sentimentAnalysis.length > 0 && (
            <Col md={6} ref={sentimentAnalysisRef}>
              {sentimentAnalysisBuilt && (
                <Card className="shadow-sm">
                  <Card.Header className="bg-warning text-light">Sentiment Analysis</Card.Header>
                  <Card.Body>
                    <SentimentAnalysisChart data={sentimentAnalysis} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          {fillerWords && (
            <Col md={6}>
              <FillerWordsAnalysis 
                fillerWords={fillerWords} 
                totalWords={call_log_feedback?.reduce((total, entry) => 
                  total + (entry.speaker === 'USER' ? entry.message.split(' ').length : 0), 0) || 0}
              />
            </Col>
          )}
          {callDynamics && (
            <Col md={6} ref={callQualityRef}>
              {callQualityBuilt && (
                <Card className="shadow-sm">
                  <Card.Header className="bg-info text-light">Talking Time Ratio</Card.Header>
                  <Card.Body>
                    <TalkingTimeChart data={[
                      { name: 'User', time: callDynamics.userTalkingTime },
                      { name: 'Assistant', time: callDynamics.assistantTalkingTime },
                    ]} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          )}
        </Row>
      </div>

      <Row className="text-center mb-5">
        <Col>
          <Button variant="primary" size="lg" onClick={() => navigate(-1)}>Practice Again</Button>
        </Col>
      </Row>

      <Row className="text-center mb-5">
        <Col>
          <Button variant="secondary" onClick={() => setDarkMode(!darkMode)}>
            Toggle Dark Mode
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AdvancedCallAnalysisPage;