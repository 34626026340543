import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

interface TipsResponse {
  [key: string]: string;
}

interface Scenario {
  id: number;
  goal: string;
  task: string;
  objective: string;
  conclusion: string;
  extra_context: string;
  audio_ref: string;
}

export const loginAPI = async (username: string, password: string) => {
  try {
    console.log('Attempting login for user:', username);
    const response = await axios.post(`${apiUrl}/api/auth/login`, { username, password });
    const { token } = response.data;
    if (token) {
      console.log('Login successful, token received');
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      console.error('Login response did not include a token');
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const getAllProgress = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${apiUrl}/api/scenarios/progress`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all progress:', error);
    throw error;
  }
};

export const getProgressByType = async (type: string | number) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${apiUrl}/api/scenarios/progress/${encodeURIComponent(type)}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching progress for type ${type}:`, error);
    throw error;
  }
};

export const startCallAPI = async (scenarioId: number = 0) => {
  try {
    console.log(`Starting call for scenario ID: ${scenarioId}`);
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, user might not be authenticated');
      throw new Error('Authentication required');
    }

    const response = await axios.get(`${apiUrl}/api/conversation/start-call?id=${scenarioId}`, {
      responseType: 'arraybuffer',
      headers: { Authorization: `Bearer ${token}` }
    });

    const data = new Uint8Array(response.data);
    const separatorIndex = data.indexOf(10);

    const jsonString = new TextDecoder().decode(data.slice(0, separatorIndex));
    const textResponse: Scenario = JSON.parse(jsonString);
    const audioBlob = new Blob([data.slice(separatorIndex + 1)], { type: 'audio/mpeg' });

    console.log('Call started successfully, scenario data received');
    return { scenario: textResponse, audioBlob };
  } catch (error) {
    console.error('Error starting call:', error);
    throw error;
  }
};

export const startConversationAPI = async (audioBlob: Blob) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('audioBuffer', audioBlob, 'audio.webm');

  const response = await axios.post(`${apiUrl}/api/conversation/converse`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    responseType: 'arraybuffer',
  });

  const data = new Uint8Array(response.data);
  const separatorIndex = data.indexOf(10);

  const jsonString = new TextDecoder().decode(data.slice(0, separatorIndex));
  const textResponse = JSON.parse(jsonString);
  const responseAudioBlob = new Blob([data.slice(separatorIndex + 1)], { type: 'audio/mpeg' });

  return { textResponse, audioBlob: responseAudioBlob };
};


export const endCallAPI = async (): Promise<any> => {
  try {
    console.log('Ending call and requesting analysis report');
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, user might not be authenticated');
      throw new Error('Authentication required');
    }

    const response = await axios.post(`${apiUrl}/api/conversation/end-call`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });

    if (response.status === 200) {
      console.log('Call ended successfully, analysis report received');
      return response.data;
    } else {
      console.error('Unexpected response status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('Error ending call:', error);
    throw error;
  }
};


export const fetchScenariosAPI = async (type?: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${apiUrl}/api/scenarios/type/${encodeURIComponent(type || '')}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching scenarios:', error);
    throw error;
  }
};

export const fetchCallData = async (scenarioId: number = 0) => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${apiUrl}/api/call-data?id=${scenarioId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};