import React from 'react';

interface RequestHistory {
    type: string;
    timestamp: string;
    duration: number;
  }
  
interface RequestHistoryProps {
  requestHistory: RequestHistory[];
}

const RequestHistory: React.FC<RequestHistoryProps> = ({ requestHistory }) => (
  <div className="row text-center my-3">
    <h3>Request History</h3>
    <ul className="list-unstyled request-history">
      {requestHistory
        .filter((request) => request.type === 'received')
        .map((request, index) => (
          <li key={index} style={{ color: request.duration > 3 ? 'red' : request.duration > 1.5 ? 'yellow' : 'green' }}>
            Received request at {request.timestamp} - {request.duration.toFixed(1)}s
          </li>
        ))}
    </ul>
  </div>
);

export default RequestHistory;
