import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface SentimentData {
  time: string;
  userSentiment: number | null;
  clientSentiment: number | null;
}

interface SentimentAnalysisChartProps {
  data: SentimentData[];
}

const SentimentAnalysisChart: React.FC<SentimentAnalysisChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis domain={[0, 1]} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="userSentiment" stroke="#8884d8" strokeWidth={2} />
        <Line type="monotone" dataKey="clientSentiment" stroke="#82ca9d" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SentimentAnalysisChart;
