import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

interface InterviewResult {
  name: string;
  department: string;
  score: number;
  cvTruthfulness: string;
  languageProficiency: string;
  technicalSkills: string;
  communication: string;
  problemSolving: string;
  culturalFit: string;
}

const interviewData: InterviewResult[] = [
  {
    name: 'Mason Thomas',
    department: 'DevOps',
    score: 69,
    cvTruthfulness: 'Discrepancies',
    languageProficiency: 'English, French',
    technicalSkills: 'JavaScript, JavaScript, Data Analysis',
    communication: '4/5',
    problemSolving: '5/5',
    culturalFit: '2/5',
  },
  {
    name: 'Ava Wilson',
    department: 'Software Development',
    score: 75,
    cvTruthfulness: 'Discrepancies',
    languageProficiency: 'Japanese, Japanese',
    technicalSkills: 'Java, Java',
    communication: '5/5',
    problemSolving: '3/5',
    culturalFit: '5/5',
  },
  {
    name: 'William White',
    department: 'Data Science',
    score: 89,
    cvTruthfulness: 'Truthful',
    languageProficiency: 'Japanese',
    technicalSkills: 'Data Analysis, Cloud Computing, C++',
    communication: '3/5',
    problemSolving: '4/5',
    culturalFit: '1/5',
  },
  {
    name: 'Olivia Brown',
    department: 'Product Management',
    score: 17,
    cvTruthfulness: 'Discrepancies',
    languageProficiency: 'German, English',
    technicalSkills: 'Machine Learning, Python, C++, DevOps, Python',
    communication: '3/5',
    problemSolving: '4/5',
    culturalFit: '3/5',
  },
];

const scoreDistributionData = [
  { name: '0-20', scoreDistribution: 15 },
  { name: '21-40', scoreDistribution: 30 },
  { name: '41-60', scoreDistribution: 20 },
  { name: '61-80', scoreDistribution: 25 },
  { name: '81-100', scoreDistribution: 10 },
];

const InterviewResultsTable: React.FC = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center', color: '#187bcd' }}>AI-Powered HR Dashboard - Virtual Interview Results</h1>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <select>
          <option>All Departments</option>
          {/* Add more department options as needed */}
        </select>

        <select>
          <option>All Experience Levels</option>
          {/* Add more experience level options as needed */}
        </select>

        <input type="number" placeholder="Minimum Score" />

        <input type="text" placeholder="Required Skills" />

        <button style={{ backgroundColor: '#007bff', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}>Apply Filters</button>
      </div>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '40px' }}>
        <thead style={{ backgroundColor: '#f4f4f4' }}>
          <tr>
            <th>Name</th>
            <th>Department</th>
            <th>Score</th>
            <th>CV Truthfulness</th>
            <th>Language Proficiency</th>
            <th>Technical Skills</th>
            <th>Communication</th>
            <th>Problem Solving</th>
            <th>Cultural Fit</th>
            <th>CV Link</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {interviewData.map((result, index) => (
            <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
              <td>{result.name}</td>
              <td>{result.department}</td>
              <td style={{ color: result.score >= 70 ? 'green' : 'orange' }}>{result.score}</td>
              <td style={{ color: result.cvTruthfulness === 'Truthful' ? 'green' : 'red' }}>{result.cvTruthfulness}</td>
              <td>{result.languageProficiency}</td>
              <td>{result.technicalSkills}</td>
              <td>{result.communication}</td>
              <td>{result.problemSolving}</td>
              <td>{result.culturalFit}</td>
              <td><a href="#" style={{ color: 'blue' }}>View CV</a></td>
              <td><button style={{ backgroundColor: '#007bff', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}>View</button></td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 style={{ textAlign: 'center', color: '#187bcd' }}>Applicant Score Distribution</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={scoreDistributionData}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="scoreDistribution" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InterviewResultsTable;
