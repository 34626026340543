import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const COLORS = ['#00C49F', '#FF8042'];

const tooltips: { [key: string]: string } = {
  positive: 'Positive words show empathy, assurance, and positivity.',
  negative: 'Negative words may convey issues or dissatisfaction.',
};

interface WordUsageData {
  name: string;
  value: number;
  color: string;
}

interface WordUsageAnalysisChartProps {
  data: WordUsageData[];
}

const WordUsageAnalysisChart: React.FC<WordUsageAnalysisChartProps> = ({ data }) => {
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const dataWithPercentage = data.map(item => ({
    ...item,
    percentage: ((item.value / total) * 100).toFixed(0),
  }));

  return (
    <div style={{ width: '100%', height: 300, position: 'relative' }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            data={dataWithPercentage}
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#82ca9d"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {dataWithPercentage.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div style={{ textAlign: 'center', position: 'absolute', bottom: 0, width: '100%' }}>
        {dataWithPercentage.map((item, index) => (
          <div
            key={index}
            data-tooltip-id="legend-tooltip"
            data-tooltip-content={tooltips[item.name]}
            style={{ display: 'inline-block', margin: '0 10px' }}
          >
            <span style={{ color: COLORS[index % COLORS.length], cursor: 'pointer' }}>
              <svg width="12" height="12" style={{ marginRight: '5px', verticalAlign: 'middle' }}>
                <rect width="12" height="12" style={{ fill: COLORS[index % COLORS.length] }} />
              </svg>
              {item.name} ({item.percentage}%)
            </span>
          </div>
        ))}
        <ReactTooltip id="legend-tooltip" />
      </div>
    </div>
  );
};

export default WordUsageAnalysisChart;
