import React from 'react';
import { useTheme } from '../../ThemeContext';
import { Switch, FormControlLabel } from '@mui/material';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <FormControlLabel
      control={
        <Switch
          checked={theme === 'dark'}
          onChange={toggleTheme}
          color="primary"
        />
      }
      label={theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
      style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
    />
  );
};

export default ThemeToggle;
