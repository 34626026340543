import { useState, useEffect, useRef, useCallback } from 'react';
import {  startConversationAPI, endCallAPI } from '../utils/api';

interface Scenario {
  id: number;
  goal: string;
  task: string;
  objective: string;
  conclusion: string;
  extra_context: string;
  audio_ref: string;
}

interface RequestHistory {
  type: string;
  timestamp: string;
  duration: number;
}

interface UseAudioRecorderProps {
  setIsInCall: (isInCall: boolean) => void;
  setCanTalk: (canTalk: boolean) => void;
  setShowSkipIntro: (showSkipIntro: boolean) => void;
  setShowTips: (showTips: boolean) => void;
  setRequestHistory: (history: (prevHistory: RequestHistory[]) => RequestHistory[]) => void;
  setChatText: (text: string) => void;
  setShowHeader: (show: boolean) => void;
  setIsIntroSkipped: (skipped: boolean) => void;
  setTipsSeenOrSkipped: (seenOrSkipped: boolean) => void;
  setTipsVisible: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const useAudioRecorder = ({
  setIsInCall,
  setCanTalk,
  setShowSkipIntro,
  setShowTips,
  setRequestHistory,
  setChatText,
  setShowHeader,
  setIsIntroSkipped,
  setTipsSeenOrSkipped,
  setTipsVisible,
}: UseAudioRecorderProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isWaitingForReply, setIsWaitingForReply] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const [initialTips, setInitialTips] = useState<string[]>(['', '', '', '']);
  const [tipVisibility, setTipVisibility] = useState<boolean[]>([false, false, false, false]);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const conversationInProgressRef = useRef(false);
  const isProcessingRequestRef = useRef(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const tipTimeoutRefs = useRef<number[]>([]);

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.addEventListener('stop', handleStop);
      } catch (err) {
        console.error('Error accessing microphone:', err);
      }
    };

    getUserMedia();

    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.removeEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.removeEventListener('stop', handleStop);
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('playing', () => setIsTalking(true));
      audioRef.current.addEventListener('pause', () => setIsTalking(false));
      audioRef.current.addEventListener('ended', () => setIsTalking(false));
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('playing', () => setIsTalking(true));
        audioRef.current.removeEventListener('pause', () => setIsTalking(false));
        audioRef.current.removeEventListener('ended', () => setIsTalking(false));
      }
    };
  }, [audioRef]);

  const handleDataAvailable = useCallback((event: BlobEvent) => {
    if (event.data.size > 0) {
      audioChunksRef.current.push(event.data);
    }
  }, []);

  const handleStop = useCallback(() => {
    if (audioChunksRef.current.length === 0) {
      // Handle no audio case
      console.warn('No audio data recorded');
    } else {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
      startConversation(audioBlob);
    }
    audioChunksRef.current = [];
  }, []);

  const startRecording = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'inactive') {
      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  }, []);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  }, []);

  const clearTipTimeouts = useCallback(() => {
    tipTimeoutRefs.current.forEach((timeout) => window.clearTimeout(timeout));
    tipTimeoutRefs.current = [];
  }, []);

  const startCall = useCallback(async (scenario: Scenario, audioBlob: Blob) => {
    setIsInCall(true);
    setCanTalk(false);
    setShowSkipIntro(true);
    setShowTips(true);

    try {
      setInitialTips([
        scenario.goal,
        scenario.task,
        scenario.objective,
        scenario.conclusion
      ]);
      setTipVisibility([true, false, false, false]);

      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;

      audio.onloadedmetadata = () => {
        const tipInterval = audio.duration / 4;
        initialTips.forEach((tip: string, index: number) => {
          if (index > 0) {
            const timeout = window.setTimeout(() => {
              setTipVisibility((prevVisibility) => {
                const newVisibility = [...prevVisibility];
                newVisibility[index] = true;
                return newVisibility;
              });
            }, index * tipInterval * 1000);
            tipTimeoutRefs.current.push(timeout);
          }
        });

        const finalTimeout = window.setTimeout(() => {
          setTipVisibility((prevVisibility) => {
            const newVisibility = [...prevVisibility];
            newVisibility[4] = true;
            return newVisibility;
          });
          setTipsSeenOrSkipped(true);
          setTipsVisible([true, true, true, true]);
        }, initialTips.length * tipInterval * 1000);
        tipTimeoutRefs.current.push(finalTimeout);
      };

      audio.play().then(() => {
        audio.onended = () => {
          setCanTalk(true);
          setShowSkipIntro(false);
          const beep = new Audio('/beep.mp3');
          beep.play();
        };
      });
    } catch (error) {
      console.error('Error starting call:', error);
      setIsInCall(false);
    }
  }, [setIsInCall, setCanTalk, setShowSkipIntro, setShowTips, setTipsSeenOrSkipped, setTipsVisible, initialTips]);

  const skipIntro = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    clearTipTimeouts();
    setTipVisibility([false, false, false, false]);
    setCanTalk(true);
    setIsIntroSkipped(true);
    setShowSkipIntro(false);
    setShowTips(false);
    setTipsSeenOrSkipped(true);
    setTipsVisible([false, false, false, false]);
  }, [clearTipTimeouts, setCanTalk, setIsIntroSkipped, setShowSkipIntro, setShowTips, setTipsSeenOrSkipped, setTipsVisible]);

  const endCall = useCallback(async (): Promise<{ analysis: string } | null> => {
    setIsInCall(false);
    stopRecording();
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    clearTipTimeouts();
    setInitialTips(['', '', '', '']);
    setTipVisibility([false, false, false, false]);
    setShowHeader(false);
    setShowSkipIntro(false);
    setShowTips(false);
    setTipsVisible([false, false, false, false]);

    try {
      const analysisReport = await endCallAPI();
      if (analysisReport) {
        return { analysis: analysisReport };
      }
      return null;
    } catch (error) {
      console.error('Error ending call:', error);
      return null;
    }
  }, [clearTipTimeouts, setIsInCall, setShowHeader, setShowSkipIntro, setShowTips, setTipsVisible, stopRecording]);

  const startConversation = useCallback(async (audioBlob: Blob) => {
    const requestStart = new Date().getTime();
    setIsWaitingForReply(true);
    isProcessingRequestRef.current = true;

    try {
      const { textResponse, audioBlob: responseAudioBlob } = await startConversationAPI(audioBlob);
      const requestEnd = new Date().getTime();
      const duration = (requestEnd - requestStart) / 1000;
      setChatText(textResponse.text);
      updateRequestHistory('received', new Date().toLocaleString(), duration);

      const audioUrl = URL.createObjectURL(responseAudioBlob);
      const audio = new Audio(audioUrl);
      audio.play().then(() => {
        setIsWaitingForReply(false);
        audio.onended = () => {
          conversationInProgressRef.current = false;
          isProcessingRequestRef.current = false;
          setIsTalking(false); // Reset isTalking when audio ends
        };
        setIsTalking(true); // Set isTalking when audio starts playing
      });
    } catch (error) {
      console.error('Error in conversation:', error);
      setIsWaitingForReply(false);
      isProcessingRequestRef.current = false;
      setIsTalking(false); // Reset isTalking in case of error
    }
  }, [setChatText]);

  const updateRequestHistory = useCallback((type: string, timestamp: string, duration: number) => {
    setRequestHistory((prevHistory: RequestHistory[]) => [
      ...prevHistory,
      { type, timestamp, duration },
    ]);
  }, [setRequestHistory]);

  return {
    isRecording,
    isWaitingForReply,
    isTalking,
    initialTips,
    tipVisibility,
    audioRef,
    clearTipTimeouts,
    setTipVisibility,
    handleDataAvailable,
    handleStop,
    startRecording,
    stopRecording,
    startCall,
    skipIntro,
    endCall,
    updateRequestHistory,
    setIsWaitingForReply,
  };
};

export default useAudioRecorder;
